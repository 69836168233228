<template>
  <div>Logging in with SSO</div>
</template>

<script>
import {mainStore} from '@/store/__STORE_main';
import {authStore} from '@/store/__STORE_auth';

export default {
  data() {
    return {
      loading: this.$auth.loading,
    };
  },
  watch: {
    loading: {
      immediate: true,
      async handler() {
        if (!this.loading) {
          mainStore.clearStatus();
          const data = {
            email: this.$auth.user.email,
            name: this.$auth.user.name,
            sub_token: this.$auth.user.sub,
          };
          await authStore
              .ssoLogin(data)
              .then(() => {
                this.$router.push('/dashboard');
              })
              .catch((err) => {
                console.error(err);
              });
        }
      },
    },
  },
  mounted() {
    mainStore.setLoading();
  },
};
</script>
